<template>
  <div class="services-page px-lg-0 px-3" v-if="pageInfo">
    <div class="container">
      <div class="title-part">
        <h1 v-scroll-animation="'animate__fadeInDown'">{{ pageInfo.services_page_title }}</h1>
        <div class="animated-image" v-scroll-animation="'animate__fadeInDown'">
          <div class="circle circle-1"></div>
          <div class="circle circle-2"></div>
          <div class="circle circle-3"></div>
          <img :src="pageInfo.services_page_image_full_path" alt="Mic Icon"/>
        </div>
      </div>
      <hr v-scroll-animation="'animate__fadeInDown'"/>
      <div class="text-under-title">
        <p class="" v-scroll-animation="'animate__fadeInDown'">
          {{ pageInfo.services_page_text }}
        </p>
      </div>
    </div>
    <div class="services-container">
      <router-link :to="{name:'SingleService',params:{id:item.form_id}}" class="service-item" v-bind:key="item.title"
           v-for="item in pageInfo.services_page_sections">
        <div class="container px-lg-5">
          <div class="row justify-content-between align-items-center">
            <div class="image-part col-lg-3">
              <div class="image-bg"></div>
              <img
                  v-scroll-animation="'animate__zoomIn'"
                  class="img-fluid"
                  :src="$store.state.generalStore.urlPrefixForImages+item.image"
                  alt="Headphones"
              />
            </div>
            <div class="text-part col-lg-9 ps-lg-5">
              <h3 v-scroll-animation="'animate__fadeInDown'">{{ item.title }}</h3>
              <p v-scroll-animation="'animate__fadeInDown'">
                {{ item.text }}
              </p>
            </div>
          </div>
        </div>
      </router-link>
    </div>
    <div class="more-details-section container px-lg-5">
      <h3 v-scroll-animation="'animate__fadeInDown'">مزيد من التفاصيل</h3>
      <router-link :to="{name:'ContactUS'}" v-scroll-animation="'animate__fadeInDown'" class="link-btn">تواصل معنا
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "services",
  data() {
    return {
      pageInfo: '',
    }
  },
  created() {
    this.$store.dispatch("loading/show");
    this.$store.dispatch("services/getAboutUsPageInfo")
        .then(() => {
          this.pageInfo = this.$store.state.services.servicesInfo;
          this.$store.dispatch("loading/hide");
        })
        .catch(err => {
          console.log(err.message)
        })

  }
};
</script>

<style lang="scss" scoped>
.services-page {
  padding: 4.5em 0 4em 0;
  background-color: #fbfcfc;

  .services-container {
    .service-item {
      display: block;
      align-items: center;
      padding: 2em 0;
      transition: 0.3s ease-in-out;
      cursor: pointer;

      &:nth-child(odd) {
        .image-part {
          justify-content: flex-end;

          .image-bg {
            left: 0;
            right: auto;
          }
        }

        .col-lg-3 {
          order: 2;
        }

        .col-lg-9 {
          order: 1;
        }
      }

      &:nth-child(even) {
        .image-part {
          .image-bg {
            background-color: $myViolet;
          }
        }
      }

      &:nth-child(3n+3) {
        .image-part {
          .image-bg {
            background-color: $myYellow;
          }
        }
      }

      &:hover {
        background-color: rgba(#000000, 0.05);
      }

      .text-part {
        h3 {
          font-size: 2.5rem;
          font-weight: 700;
          color: $myBlue;
        }
        p{
          color: #222222;
          font-size: 1.15rem;
        }
      }

      .image-part {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5em 0;

        img {
          position: relative;
          z-index: 2;
          height: 20em;
          object-fit: contain;
          width: auto;
        }

        .image-bg {
          position: absolute;
          width: 50%;
          height: 100%;
          right: 0;
          top: 0;
          border-radius: 8px;
          background-color: #45676c;
          z-index: 1;
        }
      }
    }
  }

  .title-part {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h1 {
      color: $myViolet;
      font-weight: 700;
    }

    .animated-image {
      position: relative;
      width: 6em;
      height: 6em;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 2.5em;
        height: 4em;
        object-fit: contain;
      }

      .circle {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 6em;
        height: 6em;
        border: 3px solid rgba(#000000, 0.05);
        border-radius: 50%;
        animation: myScale ease-in-out 3s infinite;
      }

      .circle.circle-2 {
        animation-delay: 1s;
      }

      .circle.circle-3 {
        animation-delay: 2s;
      }
    }
  }

  .text-under-title {
    display: flex;
    justify-content: center;
    margin-bottom: 4em;

    p {
      text-align: start;
      font-size: 1.15rem;
    }
  }

  .more-details-section {
    margin-top: 5em;
    margin-bottom: 2em;
    padding: 5em 0;
    background-color: $myViolet;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      color: white;
      font-size: 2.5rem;
      margin-bottom: 1.5em;
    }

    .link-btn {
      border: 1px solid $myYellow;
      border-radius: 1.5em;
      color: $myYellow;
      padding: 0.5em 2em;
      font-size: 1.5rem;
      font-weight: 700;
      transition: 0.3s ease-in-out;

      &:hover {
        background-color: $myYellow;
        color: white;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .services-page {
    .title-part {
      flex-wrap: wrap;
      justify-content: space-between;

      h1 {
        font-size: 2.5rem;
        order: 0;
        margin-top: 0.5em;
      }
    }

    .services-container {
      .service-item {
        .image-part {
          margin-bottom: 1.5em;
          justify-content: center;

          .image-bg {
            width: 20%;
            left: 50%;
            right: auto;
            transform: translateX(-50%);
          }

          img {
            height: 8em;
          }
        }

        .text-part {
          h3 {
            text-align: center;
          }

          p {
            text-align: center;
          }
        }

        &:nth-child(odd) {
          .image-part {
            justify-content: center;

            .image-bg {
              left: 50%;
              right: auto;
              transform: translateX(-50%);
            }
          }

          .col-lg-9 {
            order: 2;
          }

          .col-lg-3 {
            order: 1;
          }
        }
      }
    }

    .more-details-section {
      h3 {
        font-size: 2rem;
      }

      .link-btn {
        font-size: 1.4rem;
      }
    }
  }
}

@keyframes myScale {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
  }
}
</style>
