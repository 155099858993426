<template>
  <div class="single-service-page" v-if="pageInfo">
    <div class="container">
      <div class="title-part">
        <div class="top-part">
          <h1 v-scroll-animation="'animate__fadeInDown'">
            {{ pageInfo.name }}
          </h1>
          <img
              :src="
              this.$store.state.generalStore.urlPrefixForImages+pageInfo.image
            "
              alt="About us icon"
              v-scroll-animation="'animate__fadeInDown'"
          />
        </div>
        <hr v-scroll-animation="'animate__fadeInDown'"/>
        <div class="bottom-part" v-html="pageInfo.short_description">
<!--          <p-->

<!--              v-scroll-animation="'animate__fadeInDown'"-->
<!--          >-->
<!--            {{ pageInfo.short_description }}-->
<!--          </p>-->
        </div>
      </div>
      <div class="form-part">
        <div class="col-lg-8 col-md-10 col-12">
          <formio :form="JSON.parse(pageInfo.filed_json)"
                  v-on:submit="submitForm"></formio>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import {Form} from 'vue-formio';

export default {
  name: "singleService",
  components: {formio: Form},
  data() {
    return {
      pageInfo: "",
    };
  },
  methods: {
    submitForm(val) {
      console.log(val);
      this.$store.dispatch("services/submitForm", {form_id: this.$route.params.id, data: JSON.stringify(val.data)})
          .then(res => {
            this.$swal({
              title: 'هنيئاً',
              icon: 'success',
              confirmButtonText: 'حسناً',
              text: 'لقد تم ارسال طلبك بنجاح'
            }).then(res => {
              if (res.value) {
                this.$router.push({name: 'Services'});
              }
            });
          })
          .catch(err => {
            console.log(err.message);
            this.$swal({
              title: 'حدث خطأ ما',
              icon: 'error',
              confirmButtonText: 'حسناً',
              text: 'تأكد رجاءً من جميع الحقول قبل الإرسال'
            });
          });
    },
    getSingleServiceInfo(){
      this.$store.dispatch("loading/show");
      this.$store.dispatch("services/getSingleService", {form_id: this.$route.params.id})
          .then(() => {
            this.pageInfo = this.$store.state.services.singleService;
            setTimeout(()=>{
              this.$store.dispatch("loading/hide");
            },700);
          })
          .catch(err => {
            console.log(err.message);
          });
    },
  },
  watch: {
    '$route.params.id': {
      handler: function (search) {
        this.getSingleServiceInfo();
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    // this.getSingleServiceInfo();
  },
}
</script>

<style lang="scss">
.single-service-page {
  background-color: #fbfcfc;
  padding: 4.5em 0 4em 0;

  hr {
    background-color: rgba(#000000, 0.5);
  }

  .title-part {
    padding: 3.5em 0;

    .top-part {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h1 {
        color: $myViolet;
        font-weight: 700;
      }

      img {
        height: 5em;
        filter: drop-shadow(0 0 12px rgba(#000000, 0.15));
      }
    }

    .bottom-part {
      p {
        text-align: start;
        font-size: 1.3rem;
      }
    }
  }

  .form-part {
    display: flex;
    justify-content: center;

    .alert-success {
      display: none;
    }

    .form-group {
      margin-bottom: 1.5em;

      button[type=submit] {
        background-color: $myYellow;
        border-radius: 1.5em;
        border: none;
        padding: 0.35em 1.5em;
        font-weight: 700;
        box-shadow: none !important;

        &:hover {
          background-color: $myViolet;
        }
      }

      input[ref=autocompleteInput] {
        display: none;
      }

      label {
        margin-bottom: 0.5em;
      }

      .field-label {
        border-bottom: 1px solid rgba(#000000, 0.15);

        label {
          font-weight: 700;
          font-size: 1.2rem;
        }
      }

      .form-check {
        padding: 0;
      }

      .field-content {
        margin-top: 0.75em;

      }

      label {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;

        &[ref=label] {
          font-weight: 700;
          font-size: 1.1rem;
        }

        .form-check-input:checked[type=radio] {
          background-color: $myViolet;
        }

        .form-check-input:checked[type=checkbox] {
          background-color: $myViolet;
        }

        .form-check-input[type=checkbox] {
          border-radius: 3px;
        }

        .form-check-input {
          float: unset;
          margin-right: 0;
          margin-inline-end: 0.5em;
          width: 1.35em;
          height: 1.35em;
          border-radius: 50%;
        }

      }
    }

    input, textarea {
      border: none;
      outline: none !important;
      box-shadow: none !important;
      background-color: #f5f6f7;
      resize: none;
      border-radius: 8px;

      &:focus {
        outline: none !important;
      }
    }
  }
}

@media screen and (max-width: 576px){
  .single-service-page{
    padding-top: 0em;
  }
}
</style>